<template>
    <v-stepper-content :step="step">
        <v-container v-if="loading" class="fill-heigt">
            <v-progress-circular
                :size="70"
                :width="7"
                color="purple"
                indeterminate
            ></v-progress-circular>
        </v-container>
        <v-container v-else>
            <h1>Récaptitulatif</h1>
            <v-alert border="top" colored-border type="info" elevation="2">
                Si vous souhaitez faire envoyer votre commande hors de France,
                merci de nous contacter à ce propos via:
                <a href="mailto:contact@editions-de-lembuscade.com"
                    >contact@editions-de-lembuscade.com</a
                >
            </v-alert>
            <v-alert
                color="red"
                dense
                elevation="12"
                outlined
                text
                type="warning"
                >Nous ne prenons que les paiements par virement
                bancaire</v-alert
            >
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-container
                    v-if="
                        cartLines.length > 0 &&
                        cartLines[0].book &&
                        shippingAddress !== {} &&
                        billingAddress !== {}
                    "
                >
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col>
                            <h2>Le Panier</h2>
                        </v-col>
                        <v-col cols="9"></v-col>
                    </v-row>
                    <v-row>
                        <v-container>
                            <v-row>
                                <v-col> Ouvrage </v-col>
                                <v-col> Prix </v-col>
                                <v-col> Nombre </v-col>
                                <v-col> Sous-total </v-col>
                            </v-row>
                            <v-row
                                v-for="(line, index) in cartLines"
                                :key="index"
                            >
                                <v-col
                                    ><i>{{ line.book.title }}</i> (ISBN:
                                    {{ line.book.publication_info.isbn }})
                                </v-col>
                                <v-col>
                                    {{
                                        line.book.publication_info.price
                                            | currency('€', 2, {
                                                symbolOnLeft: false,
                                                spaceBetweenAmountAndSymbol: true,
                                            })
                                    }}
                                </v-col>
                                <v-col>
                                    {{ line.nbBooks }}
                                </v-col>
                                <v-col>
                                    {{
                                        (line.nbBooks *
                                            line.book.publication_info.price)
                                            | currency('€', 2, {
                                                symbolOnLeft: false,
                                                spaceBetweenAmountAndSymbol: true,
                                            })
                                    }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col></v-col>
                                <v-col></v-col>
                                <v-col></v-col>
                                <v-col>
                                    <p>
                                        Frais d'envoie en France :
                                        {{
                                            deliveryCharges
                                                | currency('€', 2, {
                                                    symbolOnLeft: false,
                                                    spaceBetweenAmountAndSymbol: true,
                                                })
                                        }}
                                    </p>
                                    <p>
                                        Total TTC :
                                        {{
                                            total
                                                | currency('€', 2, {
                                                    symbolOnLeft: false,
                                                    spaceBetweenAmountAndSymbol: true,
                                                })
                                        }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>

                    <v-row>
                        <v-divider></v-divider>
                    </v-row>
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col>
                            <h2>Adresse de Facturation</h2>
                        </v-col>
                        <v-col cols="9"></v-col>
                    </v-row>
                    <v-row>
                        <v-container>
                            <v-row>
                                <v-col
                                    ><p>
                                        Prénom : {{ billingAddress.firstname }}
                                    </p>
                                </v-col>
                                <v-col
                                    ><p>Nom : {{ billingAddress.lastname }}</p>
                                </v-col>
                                <v-col
                                    ><p>Email : {{ billingAddress.email }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col></v-col>
                                <v-col>
                                    <p class="address-p">Addresse :</p>
                                    <p class="address-p">
                                        M ou Mme {{ billingAddress.firstname }}
                                        {{ billingAddress.lastname }}
                                    </p>
                                    <p class="address-p">
                                        {{ billingAddress.streetName }}
                                    </p>
                                    <p class="address-p">
                                        {{ billingAddress.streetComplement }}
                                    </p>
                                    <p class="address-p">
                                        {{ billingAddress.streetPostalCode }}
                                        {{ billingAddress.streetCommune }}
                                    </p>
                                </v-col>
                                <v-col></v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                    <v-row>
                        <v-divider></v-divider>
                    </v-row>
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col>
                            <h2>Adresse de Livraison</h2>
                        </v-col>
                        <v-col cols="9"></v-col>
                    </v-row>
                    <v-row>
                        <v-container>
                            <v-row>
                                <v-col
                                    ><p>
                                        Prénom : {{ shippingAddress.firstname }}
                                    </p>
                                </v-col>
                                <v-col
                                    ><p>Nom : {{ shippingAddress.lastname }}</p>
                                </v-col>
                                <v-col
                                    ><p>Email : {{ shippingAddress.email }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col></v-col>
                                <v-col>
                                    <p class="address-p">Addresse :</p>
                                    <p class="address-p">
                                        M ou Mme {{ shippingAddress.firstname }}
                                        {{ shippingAddress.lastname }}
                                    </p>
                                    <p class="address-p">
                                        {{ shippingAddress.streetName }}
                                    </p>
                                    <p class="address-p">
                                        {{ shippingAddress.streetComplement }}
                                    </p>
                                    <p class="address-p">
                                        {{ shippingAddress.streetPostalCode }}
                                        {{ shippingAddress.streetCommune }}
                                    </p>
                                </v-col>
                                <v-col></v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                    <v-row>
                        <v-divider></v-divider>
                    </v-row>
                    <v-row>
                        <v-col>
                            <strong
                                >Numéro de téléphone en cas de soucis lors de la
                                livraison :</strong
                            >
                            {{ phoneNumber }}
                        </v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                    </v-row>

                    <v-row>
                        <v-divider></v-divider>
                    </v-row>
                    <v-row>
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                rules="condition"
                                name="checkbox"
                            >
                                <v-checkbox
                                    v-model="hasAcceptedCGV"
                                    :error-messages="errors"
                                    type="checkbox"
                                    required
                                >
                                    <template v-slot:label>
                                        <div>
                                            En cochant cette case, je certifie
                                            avoir pris connaissance et accepter
                                            les
                                            <a
                                                target="_blank"
                                                href="/legal/cgv"
                                                @click.stop
                                            >
                                                Conditions Générales de Ventes.
                                            </a>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                rules="condition"
                                name="checkbox"
                            >
                                <v-checkbox
                                    v-model="hasAcceptedRGPD"
                                    :error-messages="errors"
                                    type="checkbox"
                                    required
                                >
                                    <template v-slot:label>
                                        <div>
                                            En cochant cette case, je certifie
                                            avoir pris connaissance et accepter
                                            les
                                            <a
                                                target="_blank"
                                                href="/legal/rgpd"
                                                @click.stop
                                            >
                                                Règlement Général de Protection
                                                des données personelles (RGPD)
                                            </a>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-container>
                <v-btn color="primary" :disabled="invalid" @click="confirm">
                    Confirmer
                </v-btn>

                <v-btn text @click="$emit('previous_step')"> Retour </v-btn>
            </validation-observer>
        </v-container>
    </v-stepper-content>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

import { required } from 'vee-validate/dist/rules';

extend('condition', {
    ...required,
    message: 'Vous devez Accepter',
});

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        step: {
            type: Number,
            required: true,
        },
        cartLines: {
            type: Array,
            required: true,
        },
        deliveryCharges: {
            type: Number,
            required: true,
        },
        billingAddress: {
            type: Object,
            required: true,
        },
        shippingAddress: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        phoneNumber: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            hasAcceptedCGV: null,
            hasAcceptedRGPD: null,
        };
    },
    computed: {
        total() {
            let sum = 0;
            this.cartLines.forEach((line) => {
                if (line.book && line.nbBooks) {
                    sum += line.book.publication_info.price * line.nbBooks;
                }
            });
            return this.deliveryCharges + sum;
        },
    },
    methods: {
        confirm() {
            this.$refs.observer.validate();
            this.$emit('confirmCommand');
        },
    },
};
</script>
<style lang="scss" scoped>
h2 {
    text-align: left;
}
</style>
<style lang="scss">
.address-p {
    text-align: left;
}
</style>
