<template>
    <v-stepper-content :step="step">
        <h1>Adresse de Facturation</h1>
        <validation-observer ref="observer" v-slot="{ invalid }">
            <Address v-model="billingAddress" />
            <v-btn color="primary" :disabled="invalid" @click="nexStep">
                Continuer
            </v-btn>
            <v-btn text @click="$emit('previous_step')"> Retour </v-btn>
        </validation-observer>
    </v-stepper-content>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import Address from './Address.vue';
export default {
    name: 'AddressForm',
    components: { Address, ValidationObserver },
    model: {
        prop: 'billingAddress',
        event: 'change',
    },
    props: {
        step: {
            type: Number,
            required: true,
        },
        billingAddress: {
            type: Object,
            required: true,
        },
    },
    methods: {
        nexStep() {
            this.$refs.observer.validate();
            this.$emit('next_step');
        },
    },
};
</script>
