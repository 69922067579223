<template>
    <v-stepper-content step="1">
        <h1>Le Panier</h1>
        <validation-observer ref="observer1" v-slot="{ invalid }">
            <v-container>
                <v-row>
                    <v-col>Ouvrage</v-col>
                    <v-col>Prix de l'ouvrage</v-col>
                    <v-col>Nombre de copies</v-col>
                    <v-col>Sous-total ouvrage</v-col>
                    <!-- <v-col>
                                    <v-btn
                                        small
                                        class="mx-2"
                                        fab
                                        dark
                                        color="indigo"
                                        @click="addNewLine()"
                                    >
                                        <v-icon dark> mdi-plus </v-icon>
                                    </v-btn>
                                </v-col> -->
                </v-row>
                <v-row v-for="(line, index) in lines" :key="index">
                    <v-col>
                        <validation-provider
                            v-slot="{ errors }"
                            name="selectBook"
                            rules="required"
                        >
                            <v-select
                                v-model="line.book"
                                :items="opus"
                                :error-messages="errors"
                                item-text="title"
                                return-object
                                label="Ouvrage"
                                data-vv-name="selectBook"
                                required
                            ></v-select>
                            <p v-if="line.book" class="isbn-container">
                                ISBN:
                                {{ line.book.publication_info.isbn }}
                            </p>
                        </validation-provider>
                    </v-col>
                    <v-col>
                        <div>
                            {{
                                line.book
                                    ? line.book.publication_info.price
                                    : 0 | currency('€', 2, currencyOptions)
                            }}
                        </div>
                    </v-col>
                    <v-col>
                        <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="selectNbBooks"
                        >
                            <v-select
                                v-model="line.nbBooks"
                                :items="booksAvailable"
                                :error-messages="errors"
                                data-vv-name="selectNbBooks"
                                label="Nombre de livres"
                                required
                            >
                            </v-select>
                        </validation-provider>
                    </v-col>
                    <v-col>{{
                        line.book && line.nbBooks
                            ? line.book.publication_info.price * line.nbBooks
                            : 0 | currency('€', 2, currencyOptions)
                    }}</v-col>
                    <!-- <v-col>
                                    <v-btn
                                        small
                                        fab
                                        dark
                                        color="red"
                                        @click="lines.splice(index, 1)"
                                    >
                                        <v-icon dark> mdi-minus </v-icon>
                                    </v-btn>
                                </v-col> -->
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-container>
                <v-row>
                    <v-col cols="4"></v-col>
                    <v-col cols="4">
                        <v-alert
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                        >
                            Si vous souhaitez faire envoyer votre commande hors
                            de France, merci de nous contacter à ce propos via:
                            <a href="mailto:contact@editions-de-lembuscade.com"
                                >contact@editions-de-lembuscade.com</a
                            >
                        </v-alert></v-col
                    >
                    <v-col cols="2"></v-col>
                    <v-col>
                        <p>
                            Envoie en France :
                            {{
                                deliveryCharges
                                    | currency('€', 2, currencyOptions)
                            }}
                        </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4"></v-col>
                    <v-col cols="4">
                        <v-alert
                            color="red"
                            dense
                            elevation="12"
                            outlined
                            text
                            type="warning"
                            >Nous ne prenons que les paiements par virement
                            bancaire</v-alert
                        >
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col>
                        <p>
                            Total TTC :
                            {{
                                (total + deliveryCharges)
                                    | currency('€', 2, currencyOptions)
                            }}
                        </p>
                    </v-col>
                </v-row>
            </v-container>

            <v-btn
                color="primary"
                :disabled="invalid || lines.length === 0"
                @click="submit(2)"
            >
                Continuer
            </v-btn>
        </validation-observer>
    </v-stepper-content>
</template>
<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules';
import {
    extend,
    ValidationProvider,
    ValidationObserver,
    setInteractionMode,
} from 'vee-validate';
import axios from 'axios';
setInteractionMode('eager');

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
});

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
});

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
});

extend('email', {
    ...email,
    message: 'Email must be valid',
});
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    model: {
        prop: 'lines',
        event: 'change',
    },
    props: {
        step: {
            type: Number,
            required: true,
        },
        lines: {
            type: Array,
            required: true,
        },
        deliveryCharges: {
            type: Number,
            required: true,
        },
    },
    data() {
        const maxNbBooks = 5 + 1;

        return {
            booksAvailable: [...Array(maxNbBooks).keys()].slice(1),
            currencyOptions: {
                symbolOnLeft: false,
                spaceBetweenAmountAndSymbol: true,
            },
            opus: [],
        };
    },
    computed: {
        total() {
            let sum = 0;
            this.lines.forEach((line) => {
                if (line.book && line.nbBooks) {
                    sum += line.book.publication_info.price * line.nbBooks;
                }
            });
            return sum;
        },
    },
    async created() {
        const res = await axios.get(process.env.VUE_APP_API_URL + '/opuses');
        const { data } = res;
        // TODO: do it on the query to the back
        this.opus = data
            .filter((opus) => opus.publication_info)
            .map((opus) => {
                return {
                    title: opus.title,
                    publication_info: opus.publication_info,
                    id: opus.id,
                };
            });
        this.addNewLine();
    },
    methods: {
        submit(step) {
            this.$refs.observer1.validate();
            this.$emit('next_step');
            // this.step = step;
        },
        addNewLine() {
            this.lines.unshift({
                book: null,
                nbBooks: null,
            });
        },
    },
};
</script>
<style lang="scss">
#total-container {
    margin-top: 2em;
    margin-bottom: 2em;
}
</style>

<style lang="scss" scoped>
p {
    text-align: left;
}
</style>
