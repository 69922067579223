<template>
    <v-stepper-content :step="step">
        <h1>Adresse de Livraison</h1>
        <validation-observer ref="observer" v-slot="{ invalid }">
            <v-container>
                <v-row>
                    <v-col>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Le code postale"
                            rules="required|numeric|digits:10"
                        >
                            <v-text-field
                                v-model="localPhoneNumber"
                                :error-messages="errors"
                                label="Numéro de téléphone (utilisé en cas de problème lors de la livraison)"
                                required
                                @input="$emit('phoneNumber', $event)"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col> </v-col>
                </v-row>
                <v-row
                    ><v-col>
                        <v-checkbox
                            v-model="sameAsBilling"
                            label="Me livrer la commande à la même adresse que la facturation"
                            type="checkbox"
                        ></v-checkbox
                    ></v-col>
                </v-row>
                <v-row
                    ><v-col>
                        <Address
                            v-model="shippingAddress"
                            :disabled="sameAsBilling"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <v-btn color="primary" :disabled="invalid" @click="nexStep">
                Continuer
            </v-btn>
            <v-btn text @click="$emit('previous_step')"> Retour </v-btn>
        </validation-observer>
    </v-stepper-content>
</template>

<script>
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    setInteractionMode,
} from 'vee-validate';
import Address from './Address.vue';
import { required, digits, numeric } from 'vee-validate/dist/rules';
setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} ne peut être vide',
});

extend('numeric', {
    ...numeric,
    message: '{_field_} ne doit être consistuer que le chiffre.',
});

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
});

export default {
    components: {
        Address,
        ValidationObserver,
        ValidationProvider,
    },

    model: {
        prop: 'shippingAddress',
        event: 'change',
    },
    props: {
        shippingAddress: {
            type: Object,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
        phoneNumber: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            localPhoneNumber: this.phoneNumber,
            sameAsBilling: false,
        };
    },
    watch: {
        sameAsBilling(value) {
            this.$emit('sameAsBilling', value);
        },
    },
    methods: {
        nexStep() {
            this.$refs.observer.validate();
            this.$emit('next_step');
        },
    },
};
</script>
