<template>
    <v-container>
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">
                    Le Panier
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 2" step="2">
                    Adresse de facturation
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 3" step="3">
                    Adresse de Livraison
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 4" step="4">
                    Récapitulatif de la commande
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <cart-step
                    v-model="cartLines"
                    :delivery-charges="deliveryCharges"
                    :step="1"
                    @next_step="handleNextStep(2)"
                />
                <billing-address-step
                    v-model="billingAddress"
                    :step="2"
                    @next_step="handleNextStep(3)"
                    @previous_step="step--"
                />
                <shipping-address-step
                    v-model="shippingAddress"
                    :step="3"
                    :phone-number="phoneNumber"
                    @phoneNumber="phoneNumber = $event"
                    @next_step="handleNextStep(4)"
                    @previous_step="step--"
                    @sameAsBilling="sameAsBilling"
                />
                <Summary
                    :step="4"
                    :cart-lines="cartLines"
                    :delivery-charges="deliveryCharges"
                    :billing-address="billingAddress"
                    :shipping-address="shippingAddress"
                    :loading="confirmCommandLoading"
                    :phone-number="phoneNumber"
                    @previous_step="step--"
                    @confirmCommand="confirmCommand"
                />
                <!-- TODO: faire le step de confirmation -->
                <v-stepper-content step="5">
                    <h1>Confirmation</h1>
                    <h2>Votre commande est confirmée !</h2>
                    <v-container>
                        <v-row>
                            <v-col>
                                <p>
                                    Vous devriez avoir reçu un mail
                                    récapitulatif de votre commande à l'adresse
                                    : {{ billingAddress.email }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-container>
</template>
<script>
import axios from 'axios';

import CartStep from '../components/paymentProcess/Cart.vue';
import BillingAddressStep from '../components/paymentProcess/BillingAddress.vue';
import ShippingAddressStep from '../components/paymentProcess/ShippingAddress.vue';
import Summary from '../components/paymentProcess/Summary.vue';

export default {
    components: {
        CartStep,
        BillingAddressStep,
        ShippingAddressStep,
        Summary,
    },
    data() {
        return {
            step: 1,
            opus: [],
            cartLines: [],
            billingAddress: {},
            shippingAddress: {},
            phoneNumber: '',
            currencyOptions: {
                symbolOnLeft: false,
                spaceBetweenAmountAndSymbol: true,
            },
            deliveryChargesTab: [4, 5, 6, 7, 8],
            confirmCommandLoading: false,
        };
    },
    computed: {
        deliveryCharges() {
            if (this.cartLines.length <= 0) return 0;
            const nbBooks = this.cartLines.reduce((sum, line) => {
                sum += line.nbBooks;
                return sum;
            }, 0);
            if (nbBooks === 0) return 0;
            return 4 + (nbBooks - 1);
        },
    },
    async created() {
        const res = await axios.get(process.env.VUE_APP_API_URL + '/opuses');
        const { data } = res;
        // TODO: do it on the query to the back
        this.opus = data
            .filter((opus) => opus.publication_info)
            .map((opus) => {
                return {
                    title: opus.title,
                    publication_info: opus.publication_info,
                    id: opus.id,
                };
            });
    },
    methods: {
        handleNextStep(nextStep) {
            this.step = nextStep;
        },
        sameAsBilling(event) {
            if (event) {
                this.shippingAddress = JSON.parse(
                    JSON.stringify(this.billingAddress)
                );
            } else {
                this.shippingAddress = {};
            }
        },
        async confirmCommand() {
            console.log('Confirmed');
            this.confirmCommandLoading = true;
            await axios.post(process.env.VUE_APP_API_URL + '/orders', {
                contact: this.billingAddress.email,
                billingAddress: this.billingAddress,
                shippingAddress: this.shippingAddress,
                phoneNumber: this.phoneNumber,
                items: this.cartLines,
                deliveryCharges: this.deliveryCharges,
                total:
                    this.deliveryCharges +
                    this.cartLines.reduce((previous, current) => {
                        return (
                            previous +
                            current.nbBooks *
                                current.book.publication_info.price
                        );
                    }, 0),
            });
            this.$toasted.show('Vous devriez avoir reçu un mail !', {
                type: 'success',
            });
            this.confirmCommandLoading = false;
            this.step++;
            window.scrollTo(0, 0);
        },
    },
};
</script>
