<template>
    <v-container>
        <v-row>
            <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    name="Le Prénom"
                    rules="required|max:60"
                >
                    <v-text-field
                        v-model="address.firstname"
                        :counter="60"
                        :error-messages="errors"
                        label="Prénom"
                        required
                        :disabled="disabled"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    name="Le Nom"
                    rules="required|max:60"
                >
                    <v-text-field
                        v-model="address.lastname"
                        :counter="60"
                        :error-messages="errors"
                        label="Nom"
                        required
                        :disabled="disabled"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    name="L'email"
                    rules="required|email"
                >
                    <v-text-field
                        v-model="address.email"
                        :error-messages="errors"
                        label="E-mail"
                        required
                        :disabled="disabled"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col></v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <validation-provider
                    v-slot="{ errors }"
                    name="L'adresse'"
                    rules="required|max:200"
                >
                    <v-text-field
                        v-model="address.streetName"
                        :error-messages="errors"
                        :counter="200"
                        label="Adresse"
                        required
                        :disabled="disabled"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    name="Le complément d'adresse"
                    rules="max:100"
                >
                    <v-text-field
                        v-model="address.streetComplement"
                        :error-messages="errors"
                        label="Compléments d'adresse"
                        :counter="100"
                        required
                        :disabled="disabled"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <validation-provider
                    v-slot="{ errors }"
                    name="Le code postale"
                    rules="required|numeric|digits:5"
                >
                    <v-text-field
                        v-model="address.streetPostalCode"
                        :error-messages="errors"
                        label="Code Postale"
                        required
                        :disabled="disabled"
                    ></v-text-field>
                </validation-provider>
            </v-col>
            <v-col cols="8">
                <validation-provider
                    v-slot="{ errors }"
                    name="La commune"
                    rules="required|max:150"
                >
                    <v-text-field
                        v-model="address.streetCommune"
                        :error-messages="errors"
                        label="Commune"
                        :counter="150"
                        required
                        :disabled="disabled"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ValidationProvider, extend, setInteractionMode } from 'vee-validate';
import {
    required,
    digits,
    email,
    max,
    numeric,
    integer,
    // eslint-disable-next-line camelcase
    max_value,
} from 'vee-validate/dist/rules';
setInteractionMode('eager');

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
});

extend('required', {
    ...required,
    message: '{_field_} ne peut être vide',
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
});

extend('email', {
    ...email,
    message: 'Email must be valid',
});

extend('integer', {
    ...integer,
    message: '{_field_} doit être un nombre entier',
});

extend('max_value', {
    // eslint-disable-next-line camelcase
    ...max_value,
    message: '{_field_} doit être inférieur ou égal à {max}',
});

extend('numeric', {
    ...numeric,
    message: '{_field_} ne doit être consistuer que le chiffre.',
});

export default {
    name: 'AddressForm',
    components: {
        ValidationProvider,
    },
    model: {
        prop: 'address',
        event: 'change',
    },
    props: {
        address: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        nexStep() {
            this.$refs.observer.validate();
            this.$emit('next_step');
        },
    },
};
</script>
